/* @flow */

import React from "react";
import redCornerIcon from "../../assets/red-corner.svg";
import "./styles.css";

type Props = { testMode: boolean };

export default function TestModeIndicator(props: Props) {
  const { testMode } = props;

  const children = props.children !== undefined ?
    <div className="test-mode-indicator__content">
       {props.children}
    </div> : null;

  const testModeBody = (
    <div id="test-mode-indicator" className="test-mode-indicator__wrapper">
     		<div className="test-mode-indicator__container">
  			<img className="test-mode-indicator__red-corner" src={redCornerIcon} />
  			<h4 className="test-mode-indicator__header">{"Testmode is enabled"}</h4>
  	    {children}
   		</div>
    </div>
  );

  return testMode ? testModeBody
  	  : null;
}
