/* @flow */

import React from "react";
import BeneficialOwnerSummary from "../../components/BeneficialOwnerSummary";
import {actions, init, update} from "./state";


import "./styles.css";

export type Props = {
    hasBeneficiaries: boolean,
    beneficiaries: Element<any>[]
};

export default function BeneficialOwnerSummaryView(props: Props) {
    const {
        hasBeneficiaries,
        beneficiaries
    } = props;

    return hasBeneficiaries && (
        <div className="row page-break-before">
            <div className="col-xs-12 beneficial-owner__backdrop">
                <div className="beneficial-owner__container">
                    <BeneficialOwnerSummary
                        hasBeneficiaries={hasBeneficiaries}
                        beneficiaries={beneficiaries}/>
                </div>
            </div>
        </div>
    );
}

BeneficialOwnerSummaryView.actions = actions;
BeneficialOwnerSummaryView.init = init;
BeneficialOwnerSummaryView.update = update;
