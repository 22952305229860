
import WasaLogo from '../../assets/logo-small.svg'
import bankIdLogo from '../../assets/bankid_vector_rgb.svg'
import {NavLink} from 'react-router-dom';
import "./style.scss"
import React from "react";

const Terms = (props) => {



    return (<div className="terms-container">
        <div id="wasa-kredit-terms" className="partner-terms">
            <span>Genom att slutföra köpet godkänner jag </span>
            <NavLink  to={`/terms?id=${props.agreementId}`} target="_blank" >allmänna villkor</NavLink>&nbsp;och hantering av
            <NavLink  to={`/gdpr-terms?id=${props.agreementId}`} target="_blank" >&nbsp;personuppgifter</NavLink>
            <span>.</span>
        </div>
        <div className="logo-container">
                            <img src={bankIdLogo} height="100" id="bankIdLogo" alt="BankId Logo" />
                            <img src={WasaLogo} height="70" id="wasalogo" alt="Wasa Kredit Logo" />
                        
        </div>
    </div>);
};

export default Terms;