export default {
  getCheckout: checkoutId => `/api/v1/leasingCheckouts/${checkoutId}`,
  getFinancingOptions: checkoutId =>
    `/api/v1/leasingCheckouts/${checkoutId}/financingOptions`,
  putCustomer: (checkoutId, organizationNumber) =>
    `/api/v1/leasingCheckouts/${checkoutId}/customer/${organizationNumber}`,
  postCreateAgreement: () => `/v1/leasingAgreements`,
  getAgreement: agreementId => `/v1/leasingAgreements/${agreementId}`,
  putUpdateAgreement: agreementId => `/v1/leasingAgreements/${agreementId}`,
  getSignatureStatus: signatureId => `/api/v1/signatures/${signatureId}`,
    getValidateSignatory: (agreementId, personalNumber) => `/v1/leasingAgreements/${agreementId}/signatories/${personalNumber}`,
    postCreateSignature: (agreementId) => `/v1/leasingAgreements/createSignature/${agreementId}`,
  getCreateSignature: (agreementId, personalNumber) => `/v1/leasingAgreements/createSignature/${agreementId}/signatories/${personalNumber}`,
    postCreateFakeSignature: agreementId =>
    `/api/v1/agreements/${agreementId}/fake-signatures`
};
