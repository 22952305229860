/* @flow */

import React, {useState, useEffect} from "react";
import "./styles.scss";
//import {Shout} from '../../services/shout';

export default function InputV2(props) {
    const [labelText, setLabelText] = useState('');
    let {id, 
        errorMessage, 
        label, 
        type, 
        className, 
        ref, 
        readOnly, 
        value,         
        ...rest} = props;
    //let shout = new Shout();

    useEffect(() => {
        if (value !== undefined && value.length > 0) {
            setLabelText(label);
        }

    }, [value]);

    const publishInputClickShout = () => {
        if (className !== undefined && className.indexOf("disabled") !== -1) {
            return;
        }
        //shout.shoutOut("InputFieldClicked", true);
    }

    const onChangeValue = (value) => {
        if (value !== undefined && value.length > 0) {
            setLabelText(label);
        } else {
            setLabelText('');
        }
    }

    return (
        <div className={"input-container " + className} onClick={() => publishInputClickShout()}>
            <label>
                <span className="take-space">{labelText} &#160;</span>
                {
                    errorMessage &&
                    <span className="error_message">{errorMessage}</span>
                }
                <input onInput={e => onChangeValue(e.target.value)} ref={ref} id={id} name={id} readOnly={readOnly}
                       value={value}
                       type="text"
                       {...rest}
                       data-hj-suppress=""/>
            </label>
        </div>
    );
}