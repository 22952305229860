import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";

const id = x => x;

class Dispatcher extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.shape({
      route: PropTypes.object.isRequired
    }).isRequired
  };

  currentRoute = null;

  dispatchRequestedActions() {
    const { routes } = this.props;
    const { store, router } = this.context;
    const route = router.route.location.pathname;

    // Do not dispatch multiple times for the same route
    if (route === this.currentRoute) {
      return;
    }

    this.currentRoute = route;

    matchRoutes(routes, route)
      .filter(
        item =>
          item.route.component && item.route.component.componentRequestsDispatch
      )
      .map(item =>
        item.route.component.componentRequestsDispatch(store.getState(), item)
      )
      .forEach(actions => (actions && actions.map(store.dispatch)) || []);
  }

  render() {
    this.dispatchRequestedActions();
    return this.props.children;
  }
}

export default withRouter(connect(id)(Dispatcher));
