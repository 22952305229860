/* @flow */

import { createActions, handleActions } from "../../lib/actions";

export const actions = {
  ...createActions({})
};

export type State = {
  shippingCost: string,
  fundedTotal: string,
  products: []
};

export const init = {
  shippingCost: ""
};

export const update = handleActions(
  {
    GET_AGREEMENT_SUCCESS: (state, action) => ({
      ...state,
      shippingCost: action.payload.value.shipping_cost,
      fundedTotal: action.payload.value.original_funded_total,
      products: action.payload.value.funded_articles.filter(f => !f.added_item)
    })
  },
  init
);
