export const Click = {
  listener: null,

  on: function(actions) {
    this.listener = function(e) {
      (actions || []).forEach(action => action(e.pageX, e.pageY));
    };

    document.addEventListener("click", this.listener, false);
  },

  off: function() {
    if (this.listener) {
      document.removeEventListener("click", this.listener, false);
      this.listener = null;
    }
  },

  sub: function(action) {
    return {
      subscribable: "Click",
      action
    };
  }
};
