/* @flow */

import { createActions, handleActions } from "../../lib/actions";

export const actions = {
  ...createActions({})
};

export type State = {};

export const init = {};

export const update = handleActions({}, init);
