import AgreementStatusSignatory from "../AgreementStatusSignatory";
import SignAgreement from "../SignAgreement";
import AgreementSummaryV2 from "../AgreementSummaryV2";
import AgreementSignStatus from "../AgreementSignStatus";
import DefaultTestModeIndicator from "../DefaultTestModeIndicator";


export default {
  ...AgreementStatusSignatory.actions,
  ...SignAgreement.actions,
  ...AgreementSummaryV2.actions,
  ...AgreementSignStatus.actions,
  ...DefaultTestModeIndicator.actions,
};
