/* @flow */

import {
  createAction,
  createActions,
  handleActions as _handleActions
} from "redux-actions";
import { mapObject } from "./utils";

export { createAction, createActions };

export function handleActions(_handlers, defaultState) {
  const { otherwise, ...handlers } = _handlers;
  const handledActions = Object.keys(handlers);  
  const reducer = _handleActions(handlers, defaultState);

  return (state = defaultState, action) => {
    if (handledActions.indexOf(action.type) !== -1) {
      return reducer(state, action);
    }

    if (otherwise) {
      return otherwise(state, action);
    }

    return state;
  };
}

export function deferAction(action) {
  return (...args) => () => action(...args);
}

export function deferActions(actions) {
  return mapObject(actions, (key, action) => deferAction(action));
}
