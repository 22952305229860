import React from "react";
import ProductSummary from "../ProductSummary";
import OriginalProductSummary from "../OriginalProductSummary";
import BeneficialOwnerSummary from "../BeneficialOwnerSummary";
import FinancingSummary from "../FinancingSummary";
import AgreementSummaryV2 from "../AgreementSummaryV2";
import AgreementStatusAddresses from "../AgreementStatusAddresses";
import AgreementStatusSignatory from "../AgreementStatusSignatory";
import AgreementSignStatus from "../AgreementSignStatus";
import SignAgreement from "../SignAgreement";
import {Layout, Terms} from "../../components";
import DefaultTestModeIndicator from "../DefaultTestModeIndicator";

import actions from "./actions";
import {init, reducer} from "./reducer";
import t from "../../translation";
import {agreementState} from "../../utils";

import "./styles.css";

export default function AgreementStatus(props) {
    const {actions} = props;

    const {
        state,
        productSummary,
        originalProductSummary,
        financingSummary,
        agreementSummary,
        agreementAddresses,
        signAgreement,
        defaultTestModeIndicator,
        agreementSignStatus,
        agreementStatusSignatory,
        beneficiaryInfo 
    } = props.agreement;

    const renderAgreementStatusSignatory =
        state !== agreementState.signed ?
            <AgreementStatusSignatory
                {...agreementStatusSignatory}
                actions={actions}
            /> :
            null;

    const renderSignAgreement =
        state !== agreementState.signed ?
            <SignAgreement {...signAgreement} actions={actions}/> :
            null;

    const TestModeIndicator = <DefaultTestModeIndicator {...defaultTestModeIndicator} actions={actions}/>

    return <div className="agreement-site__container">
        {TestModeIndicator}
        <Layout heading={t`AGREEMENT_STATUS_VIEW_HEADER`}>
            <AgreementSignStatus {...agreementSignStatus} actions={actions}/>
            <AgreementStatusAddresses {...agreementAddresses} actions={actions}/>
            <BeneficialOwnerSummary {...beneficiaryInfo} actions={actions}/>
            <OriginalProductSummary {...originalProductSummary} actions={actions}/>
            <ProductSummary {...productSummary} actions={actions}/>
            <FinancingSummary {...financingSummary} actions={actions}/>
            <AgreementSummaryV2 {...agreementSummary} actions={actions} summaryTextsVisible={true}/>
            {renderAgreementStatusSignatory}
            {renderSignAgreement}
            <Terms agreementId={signAgreement.agreementId} />
        </Layout>
    </div>
}

AgreementStatus.actions = actions;
AgreementStatus.init = init;
AgreementStatus.reducer = reducer;
