/* @flow */

import React from "react";
import logo from "../../assets/logo.svg";
import "./styles.scss";

type Props = {
  heading: string
};

export default function Header(props: Props) {
  const { heading } = props;

  return (
    <div className="row header__container">
      <div className="header__hidden__xs">
        <div className="col-xs-8">
          <h1 className="header__h1">
            {heading}
          </h1>
        </div>
        <div className="col-xs-4 align-right">
          <img className="header__logo" src={logo} alt="" />
        </div>
      </div>

      <div className="header__visible__xs">
        <div className="col-xs-6">
          <h1 className="header__h1">
            {heading}
          </h1>
        </div>
        <div className="col-xs-6 align-right">
          <img className="header__logo" src={logo} alt="" />
        </div>
      </div>
    </div>
  );
}
