/* @flow */

import React, { Component } from "react";
import { TestModeIndicator } from "../../components";
import { actions, init, update } from "./state";

type Props = {
  testMode: bool
};

export default class DefaultTestModeIndicator extends Component <any, Props, any> {
  static actions = actions;
  static init = init;
  static update = update;


  render() {
    const { testMode } = this.props;

    return testMode ?
      <TestModeIndicator testMode={testMode}></TestModeIndicator> :
      null;
  }
}

DefaultTestModeIndicator.init = init;
