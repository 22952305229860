import { fetch } from "redux-effects-fetch";
import { getTestModeFlag } from "../testModeResolver";

export default (url, options) => {
  const headers = {
    "x-user-key": "",
    "csrf-token": window.csrfToken,
    "x-test-mode": getTestModeFlag()
  };

  options.credentials = "same-origin";

  if (!options.headers) {
    options.headers = {};
  }

  Object.assign(options.headers, headers);

  return fetch(url, options);
};
