import React from 'react';
import 'whatwg-fetch';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import Dispatcher from "./lib/dispatcher";

import { renderRoutes } from "./lib/routes";
import routes from "./routes";
import { configureApp } from "./app";
import queryString from "query-string";
import { format } from "./utils";

const agreementId = queryString.parse(window.location.search).id;



fetch("/v1/leasingAgreements/" + agreementId,
        {
            redirect: 'error'
        })
    .then(response => {
        return response.json();
    })
    .then(jsonResponse => {
        let mapped = mapProps(jsonResponse);
        let app = configureApp(mapped);
        app.renderToDOM(Root);

    })
    .catch(err => {
        console.log(err);
    });

const mapProps = (agreement) =>
{
    return {
        agreement: {
            agreementSignStatus : {
                agreementState: agreement.state,
                signatoryMessage: agreement.signatory_message,
                agreementSigners: mapSignatory(agreement.agreement_signers, true),
                remainingSignatories: mapSignatory(agreement.remaining_signatories, false),
                partnerCompanyName: agreement.partner_address.company_name,
                agreementCreatedAt: format.dateFromUtcString(
                    agreement.created_at_utc
                ),
                partnerOrganizationNumber: agreement.partner_address.organization_number

            },
            agreementStatusSignatory: {
                agreementId: agreement.id,
                showSignatoryControl: false,
                signatoryPersonNumber: "",
                personalNumber: "",
                personalNumberIsValidAsSignatory: false,
                errorStatus: "",
                showErrorMessage: false
            },
            defaultTestModeIndicator: {
                testMode:agreement.test_mode
            },
            loaded: true,
            id: agreement.id,
            state: agreement.state,
            agreementInitializer: {
                ContractLoaded: false
            },
            agreementSignatory: {
                agreementId: agreement.id,
                showSignatoryControl: false,
                areYouSignee: true,
                signatories: [],
                additionalRequiredSignatories: 0,
                signatoryMessage: agreement.signatory_message,
                personalNumber: "",
                personalNumberIsValidAsSignatory: false,
                showErrorMessage: false,
                signatoryRequired: agreement.require_beneficiary_info
            },
            financingSummary: {
                firstPayment: agreement.first_payment,
                monthlyPayment: agreement.monthly_payment,
                lastPayment: agreement.last_payment,
                contractLength: agreement.contract_length
            },
            agreementSummary: {
                infoTexts: agreement.info_texts,
                terms: agreement.terms,
                gdprTerms: agreement.gdpr_terms,
                agreementState: agreement.state,
                hasReadGeneralTerms: true,
                agreementId: agreement.id
            },
            agreementAddresses: {
                partnerAddress: agreement.partner_address,
                billingAddress: agreement.billing_address,
                deliveryAddress: agreement.delivery_address
            },
            signAgreement: {
                loading: false,
                signatureId: "",
                proceedToSigning: false,
                agreementId: agreement.id,
                signingPersonalNumber: !agreement.require_beneficiary_info ? agreement.billing_address.organization_number : "",
                errorStatusCode: "",
                termsIsValid: true,
                agreementSignatoryIsValid: !agreement.require_beneficiary_info,
                companyInformationIsValid: false,
                signingAgreement: false,
                requireBeneficiaryInfo: agreement.require_beneficiary_info
            },
            originalProductSummary: {
                shippingCost: agreement.shipping_cost.original_amount_ex_vat,
                fundedTotal: agreement.original_funded_total,
                products: agreement.funded_articles.filter(f => !f.added_item)
            },
            productSummary: {
                shippingCost: agreement.shipping_cost.amount_ex_vat,
                fundedTotal: agreement.funded_total,
                products: agreement.current_funded_articles
            },
            partnerAddress: {},
            billingAddress: {},
            deliveryAddress: {},
            beneficiaryInfo: {
                hasBeneficiaries: agreement.has_beneficiaries,
                beneficiaries: agreement.beneficiaries
            }
        }
    }
} 

const mapSignatory = (signatories, hasSigned) => {
    if (!signatories) {
        return [];
    }
    return signatories.map(s => ({name: s.name,hasSigned: hasSigned}));
};

function Root(props) {
    return (
        <BrowserRouter>
        <Dispatcher routes={routes}>
        {renderRoutes(routes, props)}
        </Dispatcher>
        </BrowserRouter>
);
}
