/* @flow */

import { createActions, handleActions } from "../../lib/actions";

import { format } from "../../utils";


export const actions = {
  ...createActions({})
};

export type State = {
  signatoryMessage: string
};

export const init = {
  agreementState: "",
  signatoryMessage: "",
  agreementSigners: [],
  remainingSignatories: [],
  partnerCompanyName: "",
  partnerOrganizationNumber: "",
  agreementCreatedAt: ""
};

const mapSignatory = (signatories, hasSigned) => {
  if (!signatories) {
    return [];
  }
  let mappedSignatories = [];
  for (var i = 0; i < signatories.length; i++) {
    mappedSignatories.push({
      name: signatories[i].name,
      hasSigned
    });
  }
  return mappedSignatories;
};

export const update = handleActions(
  {
    GET_AGREEMENT_SUCCESS: (state, action) => ({
        ...state,
        agreementState: action.payload.value.state,
        signatoryMessage: action.payload.value.signatory_message,
        agreementSigners: mapSignatory(
          action.payload.value.agreement_signers,
          true
        ),
        remainingSignatories: mapSignatory(
          action.payload.value.remaining_signatories,
          false
        ),
        partnerCompanyName: action.payload.value.partner_address.company_name,
        agreementCreatedAt: format.dateFromUtcString(
          action.payload.value.created_at_utc
        ),
        partnerOrganizationNumber: action.payload.value.partner_address.organization_number
      })  
  },
  init
);
