/* @flow */

import { createActions, handleActions } from "../../lib/actions";
import { setTestModeFlag } from "../../testModeResolver";

export const actions = {
  ...createActions({}, )
};

export const init = {
  testMode: null
};

export const update = handleActions({
    GET_CHECKOUT_SUCCESS: (state, action) => {
      setTestModeFlag(action.payload.value.test_mode);
      return {
        ...state,
        testMode: action.payload.value.test_mode
      }
    },
    GET_AGREEMENT_SUCCESS: (state, action) => {
      setTestModeFlag(action.payload.value.test_mode);
      return {
        ...state,
        testMode: action.payload.value.test_mode
      }
    },
  },
  init
);
