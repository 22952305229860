import { route } from "./lib/routes";
import Site from "./scenes/__Site";
import AgreementStatus from "./scenes/_AgreementStatus";
import SigningComplete from "./scenes/SigningComplete";
import ErrorPage from "./scenes/_ErrorPage";
import Terms from "./scenes/Terms";
import GdprTerms from './scenes/GdprTerms';

export default [
  // First we set that every route should match our Site route
  route("/", Site, {}, [
    route("/agreement-status", AgreementStatus, { exact: true }),
    route("/signing-complete", SigningComplete, { exact: true }),
    route("/terms", Terms, { exact: true }),
    route("/gdpr-terms", GdprTerms, { exact: true }),    
    route("*", ErrorPage)
  ])
];
