export function comparePaths(path1, path2) {
  const stripRegex = /(^\/|\/$)/g;
  const nothing = "";

  if (typeof path1 !== "string" || typeof path2 !== "string") {
    return false;
  }

  return (
    path1.replace(stripRegex, nothing) === path2.replace(stripRegex, nothing)
  );
}

export function mapObject(obj, fn) {
  return Object.keys(obj)
    .map(key => ({ [key]: fn(key, obj[key]) }))
    .reduce((acc, x) => Object.assign(acc, x), {});
}

export function traverse(obj, fn) {
  return mapObject(obj, (key, val) => {
    if (typeof val === "object") {
      return traverse(val, fn);
    } else {
      return fn(val);
    }
  });
}

export function shallowKeyDiff(prev, next) {
  let prevKeys = Object.keys(prev);
  let nextKeys = Object.keys(next);

  return {
    removed: prevKeys.filter(key => nextKeys.indexOf(key) === -1),
    added: nextKeys.filter(key => prevKeys.indexOf(key) === -1)
  };
}

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(actions) {
  return function(dispatch) {
    return {
      dispatch,
      actions: traverse(actions, function(action) {
        return function(...args) {
          dispatch(action(...args));
        };
      })
    };
  };
}
