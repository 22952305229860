import React from "react";
import { actions, init, update } from "./state";
import t from "../../translation";
import {
  InputV2,
} from "../../components";

import "./styles.css";

import * as validation from "../../validation";

type Props = {
  agreementId: string,
  validating: boolean,
  showSignatoryControl: boolean,
  personalNumberIsValid: boolean,
  personalNumberIsValidAsSignatory: boolean,
  errorStatus: string,
  showErrorMessage: boolean,
  validateSignatoryServerError: any,
  actions: {
    validateAdditionalSignatory: (string, string) => void,
    additionalSignatoryPersonNumberChange: string => void
  }
};

export default function AgreementStatusSignatory(props: Props) {
  const {
    agreementId,
    validating,
    showSignatoryControl,
    signatoryPersonNumber,
    personalNumberIsValid,
    personalNumberIsValidAsSignatory,
    errorStatus,
    showErrorMessage,
    validateSignatoryServerError
  } = props;
  const {
    validateAdditionalSignatory,
    additionalSignatoryPersonNumberChange
  } = props.actions;

  const validateAdditionalSignatoryOnChange = e => {
    const personNumber = e.target.value.trim();
    additionalSignatoryPersonNumberChange(personNumber);

    if (validation.isPersonNumber(personNumber)) {
      validateAdditionalSignatory(agreementId, personNumber);
    }
  };

  const signatoryPersonNumberIsEmpty = signatoryPersonNumber == '';

  
  const personalNumberClassName = 
    signatoryPersonNumberIsEmpty ? 'is-valid' : //empty will be displayed as valid but submit button will not be showing
        personalNumberIsValid &&
        personalNumberIsValidAsSignatory ? "is-valid" : "invalid";

    

    const getServerError = () => {
        const serverError = validateSignatoryServerError;
        if (serverError && serverError.error_code) {
            return {
                isKnownServerError:true,
                msg: t([serverError.error_code])
            }
        }
        return {
            isKnownServerError:false
        };
    }
    

  let notValidSignatory = null;
  if (!personalNumberIsValidAsSignatory) {
    let errorMessage = "";    

    const serverError = getServerError();

      if (serverError.isKnownServerError) {
          errorMessage = t([serverError.msg]);
      }
      else {
          errorMessage = t`Det har tyvärr inträffat ett oväntat fel. Vänligen försök igen.`;
      }

    //switch (errorStatus) {
    //    case "not-signer":
    //    errorMessage = t`Enligt uppgifterna hos Bolagsverket så är inte du firmatecknare och du kan därför inte signera avtalet. Vänligen be en firmatecknare signera avtalet.`;
    //    break;
    //    case "unknown-error":
    //        errorMessage = t`Det har tyvärr inträffat ett oväntat fel. Vänligen försök igen.`;
    //    break;
    //  default:
    //    errorMessage = t`Det har tyvärr inträffat ett oväntat fel. Vänligen försök igen.`;
    //}

    notValidSignatory = showErrorMessage
      ? errorMessage 
      : null;
    }

    /*<small className="agreement-status-signee__personal-number-error-message">
          {errorMessage}
        </small>*/



  return (  
    <div className="agreement-status-signee">
        <div className="row">
          <div className="col-xs-12">            
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <InputV2
                  className={personalNumberClassName}
                  type="text"
                  label={t`Ditt personnummer`}   
                  placeholder={t`Ditt personnummer`}   
                  name="signatoryPersonalNumber"               
                  onChange={e => validateAdditionalSignatoryOnChange(e)}
                  /*disabled={validating}*/
                  value={signatoryPersonNumber}
                  errorMessage={notValidSignatory}
                />
                {/*notValidSignatory*/}
              </div>
              <div className="col-xs-12 col-sm-6 agreement-signee__divider">
                <span className="agreement-status-signee__info-section">
                  {t`Ditt personnummer används för att se om du har rätt att teckna och för att signera avtalet via BankID.`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>);
}

AgreementStatusSignatory.actions = actions;
AgreementStatusSignatory.init = init;
AgreementStatusSignatory.update = update;
