let testMode = false;

export const setTestModeFlag = tm => {
  testMode = tm;
};

export const getTestModeFlag = () => {
  return testMode;
};

export default getTestModeFlag;
