import { handleActions } from "../../lib/actions";
import AgreementStatus from "../_AgreementStatus";
import ErrorPage from "../_ErrorPage";

export const init = {
  errorPage: ErrorPage.init,
  agreement: AgreementStatus.init,
};

export const reducer = handleActions({
  otherwise: function(state, action) {
    return {
      ...state,
      agreement: AgreementStatus.reducer(
          state.agreement,
          action
      ),
      errorPage: ErrorPage.reducer(state.errorPage, action),
  
    };
  }
}, init);
