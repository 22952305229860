import React from "react";
import { Switch, Route } from "react-router";

// Borrowed straight from the react-router-config lib, but the current released
// version does not allow you to pass additional props
export const renderRoutes = (routes, extraProps = {}) =>
  routes
    ? <Switch>
        {routes.map((route, i) =>
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={props =>
              <route.component {...props} {...extraProps} route={route} />}
          />
        )}
      </Switch>
    : null;

export function route (path, component, params = {}, routes = []) {
  return {
    path,
    component,
    routes,
    ...params
  };
}