export default {
  washPersonalIdentityNumber: personalIdentityNumber => {
    //Remove the - character
    if (personalIdentityNumber.indexOf("-") > -1) {
      personalIdentityNumber = personalIdentityNumber.replace("-", "");
    }

    //Assume that the person is born in 1900
    if (personalIdentityNumber.length === 10) {
      personalIdentityNumber = "19" + personalIdentityNumber;
    }

    return personalIdentityNumber;
  },
  formatOrganizationNumber: orgNumber => {
    const sixthDilimiter = /(\d{6})/g;
    const eightDilimiter = /(\d{8})/g;

    if (!orgNumber) return "";

    if (orgNumber.toString().length === 10)
      return orgNumber
        ? orgNumber.toString().replace(sixthDilimiter, "$1-")
        : 0;
    else if (orgNumber.toString().length === 12) {
      return orgNumber
        ? orgNumber.toString().replace(eightDilimiter, "$1-")
        : 0;
    } else {
      return orgNumber;
    }
  },
  formatThousandDelimiter: number => {
    if (!number) return 0;

    const thousandDilimiter = /\B(?=(\d{3})+(?!\d))/g;

    return number.toString().replace(thousandDilimiter, " ");
  },
  washIPv6FromEmbeddedIPv4: ipAddress => {
    return ipAddress.replace("::ffff:", "");
  }
};
