import { MousePosition, Scroll, Click } from "./subscribable";
import {
  mapStateToProps,
  mapDispatchToProps,
  shallowKeyDiff,
} from "./utils";

export function subscriptionEnhancer(createStore) {
  return function(
    reducer,
    preloadedState,
    enhancer
  ) {
    let store = createStore(reducer, preloadedState, enhancer);

    let dispatcher = new Dispatcher();

    store._getState = store.getState;
    store.withSubscriptions = (subscriptions, actions) => {
      // Monkey patch getState so that we can update subscriptions *after*
      // all reducers have been evaluated for whatever action
      store.getState = (...args) => {
        let state = store._getState(...args);
        let subs = subscriptions({
          ...mapStateToProps(state),
          ...mapDispatchToProps(actions)(store.dispatch)
        });

        dispatcher.new();

        for (let sub of subs) {
          dispatcher.add(sub.subscribable, sub.action);
        }

        dispatcher.commit();

        return state;
      };
    };

    return store;
  };
}

class Dispatcher {
  prev = {};
  next = {};
  subs = { MousePosition, Scroll, Click };

  new() {
    this.prev = { ...this.next };
    this.next = {};
  }

  commit() {
    let { added, removed } = shallowKeyDiff(this.prev, this.next);

    added.forEach(sub => this.subs[sub].on(this.next[sub]));
    removed.forEach(sub => this.subs[sub].off());
  }

  add(event, action) {
    if (!this.actions) {
      this.next[event] = [];
    }

    this.next[event].push(action);
  }
}
