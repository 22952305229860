import React from "react";
import "./styles.scss";
import {Layout} from "../../components";
import DefaultTestModeIndicator from "../DefaultTestModeIndicator";

export type Props = {
    agreementSummary: any,
    defaultTestModeIndicator: any
};

export default function GdprTerms(props: Props) {
  const {
    agreementSummary,
    defaultTestModeIndicator  
  } = props.agreement;

  const {actions} = props;

  const TestModeIndicator = <DefaultTestModeIndicator {...defaultTestModeIndicator} actions={actions}/>

  return (<div className="agreement-site__container">
        {TestModeIndicator}
        <Layout>
            <div className="gdpr-terms">        
                {agreementSummary && agreementSummary.gdprTerms && (<p dangerouslySetInnerHTML={{__html: agreementSummary.gdprTerms}}></p>)}        
            </div>
        </Layout>
    </div>);
}

