import "./polyfill/polyfills-promise";
import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import effects from "redux-effects";
import fetch, { fetchEncodeJSON } from "redux-effects-fetch";
import multi from "redux-multi";
import { createStore, compose, applyMiddleware } from "redux";
import { Provider, connect } from "react-redux";
import { matchRoutes } from "react-router-config";
import { mapStateToProps, mapDispatchToProps } from "./lib/utils";
import { subscriptionEnhancer } from "./lib/subscriptions";
import { init, reducer, actions } from "./scenes/__Site";
import { clientValidation } from "./middlewares";


export function configureApp(initialState = init) {
    // Use redux dev tools extension only if we're in development mode
    const devTools =
        process.env.NODE_ENV === "development"
            ? window &&
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
            : undefined;

    const middlewares = [
        effects,
        fetch,
        fetchEncodeJSON,
        multi,
        clientValidation
    ]

    return createApp(
        {
            actions: actions,
            init: initialState,
            update: reducer,
            subscriptions: () => [],
            middleware: middlewares
        },
        document && document.getElementById("root"),
        devTools
    );
}

function createApp(app, root, enhancer) {
    let middlewareEnhancer = applyMiddleware(...app.middleware);
    let storeEnhancer = enhancer
        ? compose(middlewareEnhancer, subscriptionEnhancer, enhancer)
        : compose(middlewareEnhancer, subscriptionEnhancer);

    let store = createStore(app.update, app.init, storeEnhancer);
    store.withSubscriptions(app.subscriptions, app.actions);

    return {
        resolveDispatchRequests(routes, path) {
            // Wait for all promises to resolve
            return Promise.all(
                // Get all matching routes for the given URL path
                matchRoutes(routes, path)
                    // Filter out all route items where the component does not have
                    // the `componentRequestsDispatch` function/method set
                    .filter(
                        item =>
                            item.route.component &&
                            item.route.component.componentRequestsDispatch
                    )
                    // Call `componentRequestsDispatch` on each matched component
                    .map(item =>
                        item.route.component.componentRequestsDispatch(
                            store.getState(),
                            item
                        )
                    )
                    // Dispatch all actions to the store
                    .map(actions => (actions && actions.map(store.dispatch)) || [])
                    // Flatten array
                    .reduce((acc, results) => [...acc, ...results], [])
                    // Filter out all dispatch results that are not an instance of
                    // `Promise` since that should mean they have already completed
                    // whatever they were supposed to do.
                    .filter(item => item instanceof Promise)
            );
        },

        getState() {
            return store.getState();
        },

        renderToDOM(view) {
            let App = connect(mapStateToProps, mapDispatchToProps(app.actions))(view);
            let component = (
                <Provider store={store}>
                    <App />
                </Provider>
            );

            // Wrap root component in React hot load AppContainer if we're in
            // a development environment
            //if (false && dev) {
            //    component = (
            //        <AppContainer>
            //            {component}
            //        </AppContainer>
            //    );
            //}

            ReactDOM.render(component, root);
        },

        renderToString(view, url) {
            let App = connect(mapStateToProps, mapDispatchToProps(app.actions))(view);
            let component = (
                <Provider store={store}>
                    <App />
                </Provider>
            );

            return ReactDOMServer.renderToString(component);
        }
    };
}




//*********** WORKING ORIGINAL ************************
//import React, { Component } from 'react';
// import { BrowserRouter } from 'react-router-dom';



//class App extends Component {
//    render() {
//        return (
//            <BrowserRouter basename={this.props.baseUrl}>
//                <div className="App">
//                        APP DIV H�r
//            </div>
//            </BrowserRouter>
//            );
//    }
//}

//export default App;
