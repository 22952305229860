import SignAgreement from "../scenes/SignAgreement";

export default store => next => action => {
  let result = next(action);
  const state = store.getState();
  const signAgreement = SignAgreement;
  
  //Has Read terms
  const termsIsValid = state.agreement.agreementSummary.hasReadGeneralTerms;
    
  console.log("termsIsValid:" +termsIsValid);

  if (state.agreement.signAgreement.termsIsValid !== termsIsValid) {
    store.dispatch(
      signAgreement.actions.updateHasReadTermsValidation(termsIsValid)
    );
  }

  return result;
};
