import { createActions, handleActions } from "../../lib/actions";

export const actions = {
  ...createActions({})
};

export type State = {
  partnerAddress: Object,
  billingAddress: Object,
  deliveryAddress: Object
};

export const init = {
    partnerAddress: {},
    billingAddress: {},
    deliveryAddress: {}
};

export const update = handleActions(
  {
    GET_AGREEMENT_SUCCESS: (state, action) => ({
      ...state,
      billingAddress: action.payload.value.billing_address,
      deliveryAddress: action.payload.value.delivery_address
    })
  },
  init
);
