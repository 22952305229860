/* @flow */

import React from "react";
import "./styles.css";
import t from "../../translation";
import { helpers } from "../../utils";

type Props = {
  productLabel: string,
  quantityLabel: string,
  priceLabel: string,
  deliveryLabel: string,
  vatLabel: string,
  totalLabel: string,
  freightLabel: string,
  freight: string,
  vat: number,
  totalPrice: string,
  currency: string,
  children: Element<any>[]
};

export default function ProductSummary(props: Props) {
  const {
    productLabel,
    quantityLabel,
    priceLabel,
    deliveryLabel,
    vatLabel,
    totalLabel,
    children,
    freightLabel,
    currency,
    freight,
    vat,
    totalPrice
  } = props;

  return (
    <div className="product-summary">
      <h2 className="product-summary__h2">{t`Aktuell sammanfattning`}</h2>
      <div className="product-summary__border--blue">
        <div className="row">
          <div className="col-xs-6 col-sm-6">
            <div className="product-summary__text product-summary__item product-summary__bold">
              {productLabel}
            </div>
          </div>
          <div className="col-xs-2 col-sm-2 product-summary__hidden__xs">
            <div className="product-summary__text product-summary__item product-summary__bold">
              {quantityLabel}
            </div>
          </div>
          <div className="col-xs-5 col-sm-4">
            <div className="product-summary__text product-summary__item product-summary__align-right product-summary__bold">
              {priceLabel}
            </div>
          </div>
        </div>
      </div>
      {children}
      <div className="product-summary__border product-summary__col">
        <div className="row">
          <div className="col-xs-6">
            <div className="product-summary__text product-summary__item">
              {freightLabel}
            </div>
          </div>
          <div className="col-xs-6">
            <div className="product-summary__text product-summary__item product-summary__align-right">
              {helpers.formatThousandDelimiter(freight)} {currency}
            </div>
          </div>
        </div>
      </div>
      <div className="product-summary__border product-summary__total-gray product-summary__col">
        <div className="row">
          <div className="col-xs-6">
            <div className="product-summary__text product-summary__item product-summary__item--bold">
              {totalLabel}
            </div>
          </div>
          <div className="col-xs-6">
            <div className="product-summary__text product-summary__item product-summary__align-right">
              {helpers.formatThousandDelimiter(totalPrice)} {currency}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type ItemProps = {
  articleNumberLabel: string,
  image: string,
  name: string,
  articleNumber: string,
  price: number,
  currency: string,
  quantity: string
};

ProductSummary.Item = function Item(props: ItemProps) {
  const {
    image,
    name,
    articleNumberLabel,
    articleNumber,
    currency,
    price,
    quantity
  } = props;

  const productImage = image
    ? <img alt="" className="product-summary__img" src={image} />
    : "";

  return (
    <div className="product-summary__border">
      <div className="row">
        <div className="col-xs-6 col-sm-6">
          <div className="product-summary__text product-summary__item">
            <span className="product-summary__item__article-name">
              {name}
            </span>
            <div>
              <span className="product-summary__item__article-number">
                {articleNumberLabel} {articleNumber}
              </span>
            </div>
            <span className="product-summary__item__article-name product-summary__hidden__md">
              Antal {helpers.formatThousandDelimiter(quantity)}
            </span>
            <div className="clearfix" />
          </div>
        </div>
        <div className="col-xs-2 col-sm-2 product-summary__hidden__xs">
          <div className="product-summary__text product-summary__item--diff">
            {helpers.formatThousandDelimiter(quantity)}
          </div>
        </div>
        <div className="col-xs-5 col-sm-4">
          <div className="product-summary__text product-summary__item--diff product-summary__align-right">
            {helpers.formatThousandDelimiter(price)} {currency}
          </div>
        </div>
      </div>
    </div>
  );
};
