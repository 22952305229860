/* @flow */

import React from "react";
import "./styles.scss";


type Props = {
  id?: string,
  className?: string,
  formTitle?: string,
  name: string,
  address: string,
  postalCode: string,
  city: string,
  orgNumber: string
};

export default function Address(props: Props) {
  const {
    id,
    className,
    formTitle,
    name,
    address,
    postalCode,
    city,
    orgNumber
  } = props;

  const classNames = className ? ["address", className].join(" ") : "address";

  const title = formTitle
    ? <h4 className="address__title">
        {formTitle}
      </h4>
    : null;

    const renderAddress = <address id={id} className="address__address" data-hj-suppress="">
        {orgNumber}
        {name} <br />
        {address} <br />
        {postalCode} {city}
        <br />
      </address>;

  return (
    <div className={classNames}>
      {title}
      <div>
        {renderAddress}
      </div>
    </div>
  );
}
