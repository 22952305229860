import React, { useEffect, useRef } from "react";
import t from "../../translation";
import { Button, Spinner } from "../../components";
import arrowIcon from "../../assets/arrow.svg";
import { actions, init, update } from "./state";
import { urls, helpers } from "../../utils";

import "./styles.scss";

type Props = {
  loading: boolean,
  proceedToSigning: boolean,
  agreementId: string,
  signingPersonalNumber: string,
  errorStatusCode: string,
  termsIsValid: boolean,
  agreementSignatoryIsValid: boolean,
  companyInformationIsValid: boolean,
  signingAgreement: boolean,
  requireBeneficiaryInfo: boolean
};

export default function SignAgreement(props: Props) {
  const {
    loading,
    agreementId,
    signingPersonalNumber,
    errorStatusCode,
    termsIsValid,
    agreementSignatoryIsValid,
    companyInformationIsValid,
    signingAgreement,
    requireBeneficiaryInfo
  } = props;

  const redirectToSigning = (agreementId, personalNumber) => {
      window.location.href = urls.getCreateSignature(agreementId, helpers.washPersonalIdentityNumber(personalNumber));
  }

  const { submitAgreement } = props.actions;

  const endElementRef = useRef(null);

  const scrollToBottom = () => { 
    console.log(endElementRef);   
    if(endElementRef.current)
      endElementRef.current.scrollIntoView({})
  };

  useEffect(() => {
    scrollToBottom()
  },
  [termsIsValid, 
    agreementSignatoryIsValid, 
    requireBeneficiaryInfo, 
    signingAgreement]);

  const buttonLabel = loading ?
    <span className="sign-agreement__button__inner-content">
        {t`Signera med BankID...`}
        <Spinner isButtonSpinner={true} />
      </span> :
    <span className="sign-agreement__button__inner-content">
        {t`Signera med BankID`}
        <img
          className="sign-agreement__button__inner-content__icon"
          src={arrowIcon}
          alt=""
        />
      </span>;

  const errorMessage =
    errorStatusCode === "ACTIVE_SESSION_IN_PROGRESS" ?
    <span className="sign-agreement__error-message">
          {`Det finns redan en aktiv bankID session för ${signingPersonalNumber}`}
          <br />
          {t`Vänligen försök igen.`}
        </span> :
    errorStatusCode === "OTHER_ERROR" ?
    <span className="sign-agreement__error-message">
            {t`Det har tyvärr inträffat ett fel.`}
            <br />
            {t`Vänligen försök igen.`}
          </span> :
    null;


  let buttonValidation = (termsIsValid &&
      agreementSignatoryIsValid &&
      companyInformationIsValid) || (termsIsValid &&
      agreementSignatoryIsValid &&
      !requireBeneficiaryInfo) ||
    (termsIsValid && signingAgreement);

  const renderButton = () =>   
    <div className="sign-agreement"  ref={endElementRef}>
      <div className="row">
        <div className="col-xs-12">
          <div className="sign-agreement__divider">
            <div className="row hidden-on-print">
              <div className="col-xs-12 col-sm-6">
                {errorMessage}
              </div>
              <div className="col-xs-12 col-sm-6">
                <div className="sign-agreement__padding">                  
                <Button
                  id="sign-agreement"
                  className="sign-agreement__button"
                  label={buttonLabel}
                  disabled={loading || !buttonValidation}
                  onClick={ ()=>
                    signingAgreement
                          ? redirectToSigning(agreementId, signingPersonalNumber) // createSignature(agreementId, signingPersonalNumber) 
                      : submitAgreement()}
                />                
                </div>
                
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="info-container">                 
                  <div className="info__text">
                    <span className="info__header">
                      {t`SIGN_AGGREEMENT_FOOT_NOTE`}
                    </span>
                  </div>
                </div>
              </div>
            </div>          
          </div>
        </div>
      </div>
    </div>;

    return (<>
      {renderButton()}
    </>)
}
SignAgreement.actions = actions;
SignAgreement.init = init;
SignAgreement.update = update;
