/* @flow */

import {createActions, handleActions} from "../../lib/actions";

export const actions = {
    ...createActions({})
};

export type State = {
    hasBeneficiaries: boolean,
    beneficiaries: []
};

export const init = {
    hasBeneficiaries: false,
    beneficiaries: []
};

export const update = handleActions(
    {
        GET_AGREEMENT_SUCCESS: (state, action) => ({
            ...state,
            hasBeneficiaries: action.payload.value.has_beneficiaries,
            beneficiaries: action.payload.value.beneficiaries
        })
    },
    init
);
