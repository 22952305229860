/* @flow */

import React from "react";
import t from "../../translation";
import { Header, Footer } from "../";

type Props = {
  heading?: string,
  children: Element<any>[]
};

export default function Layout(props: Props) {
  const { heading, children } = props;

  return (
    <div className="container">
      <Header heading={heading} />
      {props.children}
    </div>
  );
}
