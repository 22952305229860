import React from "react";
import { actions, init, update } from "./state";
import "./styles.scss";

export type Props = {
  infoTexts: [],
  terms: any,
  gdprTerms: any,
  agreementState: string,
  summaryTextsVisible: boolean,  
  partnerId: any,
  agreementId: any
};

export default function AgreementSummaryV2(props: Props) {
  const {
    infoTexts,
    summaryTextsVisible,
    agreementId
  } = props;

  const summaryTexts =
    (<>
      {(infoTexts || []).map((info, index) =>
        <div className="row page-break-before" key={index}>          
          <div className="col-xs-12 agreementsummary-v2">
            <div className="info-container">
              <div className="info__text">
                  <span className="financing-info__header">
                    {info.header}
                  </span>
                  {(info.body || '').split('.').map((btext, index) => 
                    btext != '' ?
                      <p key={index}>- {btext.trim()}.</p> : ''                    
                  )}
              </div>
            </div>            
          </div>
        </div>
      )}
    </>);

const agreementSummaryTexts =
  summaryTextsVisible ?
  summaryTexts :
  null;

  return (
    <>      
      {agreementSummaryTexts}
    </>
  );
}
AgreementSummaryV2.actions = actions;
AgreementSummaryV2.init = init;
AgreementSummaryV2.update = update;
