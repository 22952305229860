/* @flow */

import { createActions, handleActions } from "../../lib/actions";

export const actions = {
    ...createActions({}, "CHANGE_HAS_READ_GENERAL_TERMS")
};

export type State = {
    infoTexts: any[],
    terms: any,
    gdprTerms: any,
    agreementState: string,
    partnerId: string,
    agreementId: any
};

export const init = {
    infoTexts: [],
    agreementState: "",
    terms: "",
    gdprTerms: "",
    agreementId: ""
};

export const update = handleActions(
    {
        GET_AGREEMENT_SUCCESS: (state, action) => ({
            ...state,
            infoTexts: action.payload.value.info_texts,
            terms: action.payload.value.terms,
            gdprTerms: action.payload.value.gdpr_terms,
            agreementState: action.payload.value.state,
            agreementId: action.payload.value.id
        }),
        CHANGE_HAS_READ_GENERAL_TERMS: (state, action) => ({
            ...state,
            hasReadGeneralTerms: action.payload
        })
    },
    init
);
