import React, { Component } from "react";
import t from "../../translation";

import { actions, init, update } from "./state";

import signingCheck from "../../assets/signing-check.svg";

import "./styles.css";


export default class SigningInProgress extends Component {
 render() {
    return (
      <div>
        <div className="signing-complete-container">
          <div className="signing-complete-header">
            <h1 className="signing-complete-header__h1">
              <img className="signing-complete-header__h1__check" src={signingCheck} alt=""/> {t `Signerat!`}
            </h1>
          </div>
          <div className="signing-complete__content">
            <h2 id="thank-you-message" className="signing-complete__header__h2">{t`SIGNING_COMPLETE_HEADER`}</h2>
            <h3 className="signing-complete__content__header">{t `Vad händer nu?`}</h3>
            <div className="row signing-complete__content__whats-next">
              <div className="col-xs-12 col-sm-8">
                <p className="signing-complete__content__whats-next__p">
                  {t `När avtalet är godkänt får du en bekräftelse via e-post.`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
SigningInProgress.actions = actions;
SigningInProgress.init = init;
SigningInProgress.update = update;
