/* @flow */

import { bind } from "redux-effects";
import fetcher from "../../fetcher";
import { createActions, handleActions } from "../../lib/actions";

import { urls, helpers } from "../../utils";
import * as validation from "../../validation";

export const actions = {
  ...createActions(
    {},
    "VALIDATE_ADDITIONAL_SIGNATORY_SUCCESS",
    "VALIDATE_ADDITIONAL_SIGNATORY_ERROR",
    "VALIDATING_ADDITIONAL_SIGNATORY",
    "ADDITIONAL_SIGNATORY_PERSON_NUMBER_CHANGE"
  ),
  validateAdditionalSignatory: (agreementId, personNumber) => [
    actions.validatingAdditionalSignatory(),
    bind(
        fetcher(urls.getValidateSignatory(agreementId, helpers.washPersonalIdentityNumber(personNumber)), {
        method: "GET"
      }),
      actions.validateAdditionalSignatorySuccess,
      actions.validateAdditionalSignatoryError
    )
  ]
};

export type State = {
  signatoryPersonNumber: string,
  showSignatoryControl: boolean,
  personalNumberIsValid: boolean,
  personalNumberIsValidAsSignatory: boolean,
  errorStatus: string,
  showErrorMessage: boolean,
  validateSignatoryServerError: any
};

export const init = {
  showSignatoryControl: false,
  signatoryPersonNumber: "",
  personalNumber: "",
  personalNumberIsValidAsSignatory: false,
  errorStatus: "",
  showErrorMessage: false,
  validateSignatoryServerError: null
};

export const update = handleActions(
  {
    ADDITIONAL_SIGNATORY_PERSON_NUMBER_CHANGE: (state, action) => ({
      ...state,
      additionalRequiredSignatories: 0,
      signatories: [],
      signatoryPersonNumber: action.payload,
      personalNumberIsValid: validation.isPersonNumber(action.payload),
      personalNumberIsValidAsSignatory: false,
      showErrorMessage: false
    }),
    VALIDATING_ADDITIONAL_SIGNATORY: (state, action) => ({
      ...state,
      validating: true
    }),
    VALIDATE_ADDITIONAL_SIGNATORY_SUCCESS: (state, action) => ({
      ...state,
        validating: false,
        errorStatus: "",
        personalNumberIsValidAsSignatory: action.payload.value.is_ok,
        showErrorMessage: !action.payload.value.is_ok,
        validateSignatoryServerError: null
    }),
    VALIDATE_ADDITIONAL_SIGNATORY_ERROR: (state, action) => ({
      ...state,
      validating: false,
      personalNumberIsValidAsSignatory: false,
      errorStatus: "unknown-error",
      showErrorMessage: true,
      validateSignatoryServerError: action.payload.value
    }),
    GET_AGREEMENT_SUCCESS: (state, action) => ({
      ...state,
      agreementId: action.payload.value.id
    }),
    UPDATE_HAS_READ_TERMS_VALIDATION: (state, action) => ({
      ...state,
      showSignatoryControl: action.payload
    })
  },
  init
);
