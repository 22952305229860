/* @flow */

import React from "react";
import "./styles.css";

type Props = {
  name: string,
  status: any
};

export default function SignatoryStatus(props: Props) {
  let { name, status } = props;

  const renderSignatoryStatus = <span data-hj-suppress className="signatory-status__name">
      {name}
    </span>;

  return (
    <div className="signatory-status">
      {renderSignatoryStatus}
      <span className="signatory-status__status">
        {status}
      </span>
    </div>
  );
}
