import React from "react";
import t from "../../translation";
import {  SignatoryStatus } from "../../components";
import "./styles.scss";
import { actions, init, update } from "./state";
import checkIcon from "../../assets/ic_check.svg";
import signIcon from "../../assets/icon_sign.svg";

import { agreementState as agState } from "../../utils";

type Props = {
  signatoryMessage: string,
  partnerCompanyName: string
};

export default function AgreementSignStatus(props) {
  const {
    agreementState,
    agreementSigners,
    remainingSignatories,
    signatoryMessage,
    partnerCompanyName,
    partnerOrganizationNumber,
    agreementCreatedAt
  } = props;

  const signedIcon = <img src={checkIcon} alt={t`Har signerat`} />;

  const renderStatusHeader =
    agreementState !== agState.signed ?
    <div className="agreement-sign-status__header-box">
          <div className="agreement-sign-status__welcome-text-wrapper">
            <h1 className="agreement-sign-status__h1">{t`Hej!`}</h1>
            <p className="agreement-sign-status__welcome-text">
              {t`AGREEMENT_SIGN_STATUS_WELCOME_TEXT`}
            </p>
          </div>
          <div className="agreement-sign-status__sign-icon-wrapper">
            <img
              className="agreement-sign-status__sign-icon"
              src={signIcon}
              alt={t`Signera`}
            />
          </div>
        </div> :
    null;

    const renderRemainingSignatories =
    agreementState !== agState.signed ?
    (remainingSignatories || [])
    .map((remainingSignatory, index) =>
      <SignatoryStatus
              key={index}
              name={remainingSignatory.name}
              status={t`Väntar på svar...`}
            />
    ) :
    null;

  const renderRequestedSignatoriesMessage =
    agreementState !== agState.signed ?
    <p
        >{t`När alla firmatecknare har signerat får du en bekräftelse via mail.`}</p> :
    null;

  const renderSignatoryMessage =
    agreementState !== agState.signed ?
    <div>
          {/*<span className="agreement-sign-status__signatory-message-header">{t`Meddelande`}</span>*/}
          <p className="agreement-sign-status__signatory-message">
            {signatoryMessage}
          </p>
        </div> :
    null;

  return (
    <div className="agreement-sign-status">
      {renderStatusHeader}
      <div className="agreement-sign-status__signatory-status">
        {renderSignatoryMessage}
        <div className="agreement-sign-status__order-info-blocks">
          <div className="agreement-sign-status__order-info-block">
            <span className="agreement-sign-status__order-info-block__label">
              {t`Datum`}
            </span>
            <span className="agreement-sign-status__order-info-block__text">
              {agreementCreatedAt}
            </span>
          </div>
          <div className="agreement-sign-status__order-info-block agreement-sign-status__order-info-block-last">
            <span className="agreement-sign-status__order-info-block__label">
              {t`Butik`}
            </span>
            <span className="agreement-sign-status__order-info-block__text">
              {partnerCompanyName} ({partnerOrganizationNumber})
            </span>
          </div>
        </div>
        {(agreementSigners || [])
          .map((signer, index) =>
            <SignatoryStatus
              key={index}
              name={signer.name}
              status={signedIcon}
            />
          )}
        {renderRemainingSignatories}
        {renderRequestedSignatoriesMessage}
      </div>
    </div>
  );
}
AgreementSignStatus.actions = actions;
AgreementSignStatus.init = init;
AgreementSignStatus.update = update;
