import React from "react";
import t from "../../translation";
import "./styles.css";
import { actions, init, update } from "./state";
import { helpers } from "../../utils";


export type Props = {
  monthlyPayment: any,
  firstPayment: any,
  lastPayment: any,
  contractLength: string
};

export default function FinancingSummary(props: Props) {
  const { contractLength, firstPayment, monthlyPayment, lastPayment } = props;

  const residualValueBox =
    <div className="financing-summary__block--3">
     <span className="financing-summary__block__span financing-summary__block__span--divider-bottom financing-summary__block__span--bold">{t`Restvärde`}</span>
     <span className="financing-summary__block__span--big financing-summary__block__span--divider-bottom ">
       {helpers.formatThousandDelimiter(lastPayment.total)} kr
     </span>
     <span className="financing-summary__block__span financing-summary__block__span--arial">
       {t`Månad `} {lastPayment.month}
     </span>
   </div>;

  const renderResidualValueBox = (lastPayment.total != 0) ? residualValueBox : null;

  const renderMonthlyPaymentTotal = <span className="no-wrap">{ helpers.formatThousandDelimiter(monthlyPayment.total) }</span>;

  return (
    <div className="row financing-summary">
      <div className="financing-summary--backdrop">
        <div className="financing-summary__no-gutter">
          <div className="financing-summary__block--1">
            <div className="financing-summary__block--1-1">
              <span className="financing-summary__block__span--highlight">
                {renderMonthlyPaymentTotal}
                {t` kr/mån`}
              </span>
              <span className="financing-summary__block__span--highlight-faded">
                {contractLength}
                {t` månader`}
              </span>
            </div>
          </div>
          <div className="financing-summary__block--2">
            <div className="financing-summary__block--3">
              <span className="financing-summary__block__span financing-summary__block__span--divider-bottom financing-summary__block__span--bold">{t`Första betalning`}</span>
              <span className="financing-summary__block__span--big financing-summary__block__span--divider-bottom">
                {helpers.formatThousandDelimiter(firstPayment.total)} kr
              </span>
              <span className="financing-summary__block__span financing-summary__block__span--arial">
                {t`Månad `}
                {firstPayment.month}
              </span>
            </div>
            <div className="financing-summary__block--3">
              <span className="financing-summary__block__span financing-summary__block__span--divider-bottom financing-summary__block__span--bold">{t`Månadskostnad`}</span>
              <span className="financing-summary__block__span--big financing-summary__block__span--divider-bottom ">
                {helpers.formatThousandDelimiter(monthlyPayment.total)} kr
              </span>
              <span className="financing-summary__block__span financing-summary__block__span--arial">
                {t`Månad `} {monthlyPayment.month_from} - {contractLength}
              </span>
            </div>
            { renderResidualValueBox }
          </div>
        </div>
      </div>
    </div>
  );
}
FinancingSummary.actions = actions;
FinancingSummary.init = init;
FinancingSummary.update = update;
