export default {
  organizationNumber: value => {
    return value;
  },
  amount: value => {
    return value;
  },
  dateFromUtcString: value => {
    try {
      return new Date(value).toISOString().substring(0, 10);
    } catch (e) {
      return value;
    }
  }
};
