/* @flow */
import { bind } from "redux-effects";
import fetcher from "../../fetcher";
import { createActions, handleActions } from "../../lib/actions";

import { urls, helpers } from "../../utils";

export const actions = {
    ...createActions(
        {},
        "SUBMIT_AGREEMENT",
        "CREATE_SIGNATURE_SUCCESS",
        "CREATE_SIGNATURE_ERROR",
        "UPDATE_AGREEMENT_SUCCESS",
        "UPDATE_AGREEMENT_ERROR",
        "UPDATE_HAS_READ_TERMS_VALIDATION",
        "UPDATE_AGREEMENT_SIGNATORY_VALIDATION",
        "UPDATE_COMPANY_INFORMATION_VALIDATION"
    ),
    createSignature: (agreementId, personalNumber) =>
        bind(
          fetcher(urls.getCreateSignature(agreementId, helpers.washPersonalIdentityNumber(personalNumber)), {
              method: "GET",
              headers: {
                  'Content-Type': 'application/json'
              }
      })
          ,
      actions.createSignatureSuccess,
      actions.createSignatureError
    ),
  updateAgreement: (agreementId, agreementModelFromMiddleware) =>
    bind(
      fetcher(urls.putUpdateAgreement(agreementId), {
        method: "PUT",
        body: JSON.stringify(agreementModelFromMiddleware)
      }),
      actions.updateAgreementSuccess,
      actions.updateAgreementError
    )
};
export type State = {
  loading: boolean,
  signatureId: string,
  proceedToSigning: boolean,
  agreementId?: string,
  signingPersonalNumber?: string,
  errorStatusCode: string,
  termsIsValid: boolean,
  agreementSignatoryIsValid: boolean,
  companyInformationIsValid: boolean,
  requireBeneficiaryInfo: boolean
};

export const init = {
  loading: false,
  signatureId: "",
  proceedToSigning: false,
  agreementId: null,
  signingPersonalNumber: null,
  errorStatusCode: "",
  termsIsValid: true,
  agreementSignatoryIsValid: false,
  companyInformationIsValid: false,
  signingAgreement: false,
  requireBeneficiaryInfo: true
};

export const update = handleActions(
  {
    GET_AGREEMENT_SUCCESS: (state, action) => {
      let newState = {
        ...state,
        agreementId: action.payload.value.id,
        requireBeneficiaryInfo: action.payload.value.require_beneficiary_info
      }

      if (!newState.requireBeneficiaryInfo) {
        newState.signingPersonalNumber = action.payload.value.billing_address.organization_number;
        newState.agreementSignatoryIsValid = true;
      }
      
      return newState
    },
    CREATE_SIGNATURE_SUCCESS: (state, action) => ({
      ...state,
      signatureId: action.payload.value.signing_id,
      proceedToSigning: true
    }),
    SET_SIGNATURE_ID: (state, action) => ({
      ...state,
      proceedToSigning: false,
      loading: false
    }),
    CREATE_SIGNATURE_ERROR: (state, action) => ({
      ...state,
      errorStatusCode: "ACTIVE_SESSION_IN_PROGRESS",
      loading: false
    }),
    SIGNATORY_PERSON_NUMBER_CHANGE: (state, action) => ({
      ...state,
      signingPersonalNumber: action.payload
    }),
    SUBMIT_AGREEMENT: (state, action) => ({
      ...state,
      loading: true
    }),
    UPDATE_AGREEMENT_ERROR: (state, action) => ({
      ...state,
      errorStatusCode: "OTHER_ERROR",
      loading: false
    }),
    UPDATE_HAS_READ_TERMS_VALIDATION: (state, action) => ({
      ...state,
      termsIsValid: action.payload
    }),
    UPDATE_AGREEMENT_SIGNATORY_VALIDATION: (state, action) => ({
      ...state,
      agreementSignatoryIsValid: action.payload
    }),
    UPDATE_COMPANY_INFORMATION_VALIDATION: (state, action) => ({
      ...state,
      companyInformationIsValid: action.payload
    }),
    VALIDATE_ADDITIONAL_SIGNATORY_SUCCESS: (state, action) => ({
      ...state,
        signingAgreement: action.payload.value.is_ok
    }),
    ADDITIONAL_SIGNATORY_PERSON_NUMBER_CHANGE: (state, action) => ({
      ...state,
      signingPersonalNumber: action.payload,
      signingAgreement: false
    })
  },
  init
);
