import React from "react";
import t from "../../translation";
import { Address } from "../../components";
import "./styles.scss";
import { actions, init, update } from "./state";
import { helpers } from "../../utils";

type Props = {
  partnerAddress: {},
  billingAddress: {},
  deliveryAddress: {}
};

export default function AgreementStatusAddresses(props) {
  const {  billingAddress, deliveryAddress } = props;

  const renderOrganizationNumber = <span data-hj-suppress className="agreement-status-addresses__address">
      {helpers.formatOrganizationNumber(
        billingAddress.organization_number
      )}
    </span>;

  const renderRecipientName = <span data-hj-suppress className="agreement-status-addresses__address">
      {deliveryAddress.recipient_name}
    </span>;

  return (
    <div className="agreement-status-addresses">
      <div className="agreement-status-addresses__address-section">
        <div className="agreement-status-addresses__half-section">
          <div className="agreement-status-addresses__divider">
            <Address
              formTitle={t`Faktureringsadress`}
              name={billingAddress.company_name}
              address={billingAddress.street_address}
              postalCode={billingAddress.postal_code}
              city={billingAddress.city}
            />
          </div>
          <h4 className="agreement-status-addresses__title">
            {t`ORGANIZATION_NUMBER_LABEL`}
          </h4>
          {renderOrganizationNumber}
        </div>
        <div className="agreement-status-addresses__half-section">
          <div className="agreement-status-addresses__divider">
            <Address
              formTitle={t`Leveransadress`}
              name={deliveryAddress.company_name}
              address={deliveryAddress.street_address}
              postalCode={deliveryAddress.postal_code}
              city={deliveryAddress.city}
            />
          </div>
          <h4 className="agreement-status-addresses__title">
            {t`Leveransmottagare`}
          </h4>
          {renderRecipientName}
        </div>
      </div>
    </div>
  );
}
AgreementStatusAddresses.actions = actions;
AgreementStatusAddresses.init = init;
AgreementStatusAddresses.update = update;
