import { handleActions } from "../../lib/actions";

export const init = {
  status: "404",
  statusText: "Page Not Found",
  xCorrelationId: "00000000-0000-0000-0000-000000000000",
  value: "This is the default error message"
};

export const reducer = handleActions(
  {
    GET_CHECKOUT_ERROR: (state, action) => ({
      ...state,
      status: action.payload.status,
      statusText: action.payload.statusText,
      xCorrelationId: action.payload.headers.get("x-correlation-id"),
      value: action.payload.value
    }),
    otherwise: (state, action) => ({
      ...state
    })
  },
  init
);
