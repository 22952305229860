let _partnerDomain = null;

const _postMessage = (data) => {
  if (window.self !== window.top && _partnerDomain !== null) {
    window.top.postMessage(data, _partnerDomain);
  }
}


export default {
  postMessage: function(data, domain) {
    window.top.postMessage(data, domain);
  },
  resize: height => {
    _postMessage({
      type: "resize",
      value: height
    });
  },
};
