const swe = {
  AGREEMENT_SIGN_STATUS_WELCOME_TEXT: "Det finns ett avtalsvillkor för dig att signera. Signera gör du enkelt och direkt med BankID.",
  OLD_AGREEMENT_SIGN_STATUS_WELCOME_TEXT: "Det finns ett leasingvillkor för dig att signera. Signera gör du enkelt och direkt med BankID.",
  AGREEMENT_VIEW_HEADER: "Signering",
  AGREEMENT_STATUS_VIEW_HEADER: "Avtalsvillkor",
  BACK_TO_LEASING_TERMS: "Tillbaka till avtalsvillkoren",
  OLD_BACK_TO_LEASING_TERMS: "Tillbaka till leasingvillkoren",
  BANK_ID_STATUS_TEXT_READY_FOR_SIGNING: "Öppna din BankID-app för att signera",
  CHANGE_ORGANIZATION_NUMBER: "Byt organisationsnummer",
  CHANGE_PAYMENT_METHOD: "Byt betalsätt",
  ENTER_CORRECT_ORGANIZATION_NUMBER: "Ange ett giltigt organisationsnummer",
  GDPR_TERMS_HEADER: "Så här behandlar vi dina personuppgifter",
  LEASING_TERMS: "Leasingvillkor",
  ORGANIZATION_DETAILS_EMAIL_LABEL: "Din e-post",
  ORGANIZATION_DETAILS_RECIEVER_LABEL: "Leveransmottagare",
  ORGANIZATION_LOOKUP_INFO: "Vi genomför en kreditupplysning för att kunna presentera avtalsvillkoren för ert företag.",
  ORGANIZATION_LOOKUP_INFO_OLD: "Vi genomför en kreditupplysning för att kunna presentera ett leasingvillkor för ert företag.",
  ORGANIZATION_NUMBER_LABEL: "Organisationsnummer",
  PRICES_EXCLUDING_VAT: "Samtliga belopp är exklusive moms.",
  PROCEED_TO_AGREEMENT_BUTTON_LABEL: "Vidare till signering",
  PROCEED_TO_AGREEMENT_BUTTON_ACTION_LABEL: "Går vidare till signering",
  SIGN_AGGREEMENT_FOOT_NOTE: "Notera att även Wasa Kredit AB måste godkänna avtalsvillkoren för att avtalet ska bli bindande. När så skett kommer en avtalsbekräftelse att skickas till din e-postadress. Detta sker vanligtvis inom en arbetsdag efter att avtalet signerats av behörig företrädare för kund.",
  OLD_SIGN_AGGREEMENT_FOOT_NOTE: "Notera att även Wasa Kredit AB måste godkänna leasingvillkoren för att avtalet ska bli bindande. När så skett kommer en avtalsbekräftelse att skickas till din e-mailadress. Detta sker vanligtvis inom en arbetsdag efter att avtalet signerats av behörig företrädare för kund.",
  SIGNATORY_INFO_TEXT: "Ditt personnummer används för att se om du har rätt att teckna och för att signera avtalet via BankID.",
  SIGNING_COMPLETE_HEADER: "Tack! Du har nu signerat avtalsvillkoren.",
  SIGNING_IN_PROGRESS_HEADER: "Signera med BankID",
  YOUR_ORGANIZATION_NUMBER_LABEL: "Ert organisationsnummer",
  TOGGLE_INSURANCE_LABEL: "Inkludera försäkring",
  TEST_COMPANY_ORGANIZATION_TEXT_HEADER: "Test company",
  TEST_COMPANY_ORGANIZATION_TEXT: "To complete the checkout, use our test company with organisation number 6806249022.",
  USE_ORGANIZATION_BUTTON_LABEL: "Use test company",
  USING_ORGANIZATION_BUTTON_LABEL: "Using test company",
  SIGNATORY_NOT_ALLOWED_TO_SIGN_FOR_ORGANIZATION: "Enligt uppgifterna hos Bolagsverket så är inte du firmatecknare och du kan därför inte signera avtalet. Vänligen be en firmatecknare signera avtalet.",
  SIGNATORY_ALREADY_SIGNED_AGREEMENT: "Personen har redan signerat avtalet."

};

export { swe };
