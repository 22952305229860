/* @flow */

import React from "react";
import OriginalProductSummary from "../../components/OriginalProductSummary";
import { actions, init, update } from "./state";


import "./styles.css";

export type Props = {
  shippingCost: string,
  fundedTotal: string,
  products: any[]
};

export default function OriginalProductSummaryView(props: Props) {
  const { shippingCost, fundedTotal, products } = props;
  return (
    <div className="row page-break-before">
      <div className="col-xs-12 product-summary__backdrop">
        <div className="product-summary__container">
          <OriginalProductSummary
            productLabel="Produkt"
            quantityLabel="Antal"
            priceLabel="Pris"
            deliveryLabel="Leverans"
            freightLabel="Frakt"
            freight={shippingCost}
            currency="kr"
            vatLabel="Moms"
            vat={300}
            totalLabel={"Totalt exkl. moms"}
            totalPrice={fundedTotal}
          >
            {(products || [])
              .map((product, index) =>
                <OriginalProductSummary.Item
                  key={index}
                  name={product.product_name}
                  image={product.image_url}
                  articleNumberLabel="Art nr. "
                  articleNumber={product.product_id}
                  quantity={product.quantity}
                  price={product.funded_amount_ex_vat}
                  currency="kr"
                />
              )}
          </OriginalProductSummary>
        </div>
      </div>
    </div>
  );
}

OriginalProductSummaryView.actions = actions;
OriginalProductSummaryView.init = init;
OriginalProductSummaryView.update = update;
