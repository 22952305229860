export const Scroll = {
  listener: null,

  on: function(actions) {
    let running = false;

    this.listener = function(e) {
      if (running) return;

      running = true;

      // Throttle action dispatch to frame rate
      requestAnimationFrame(function() {
        (actions || []).forEach(action => action(e.pageX, e.pageY));
        running = false;
      });
    };

    document.addEventListener("scroll", this.listener, false);
  },

  off: function() {
    if (this.listener) {
      document.removeEventListener("scroll", this.listener, false);
    }
  },

  sub: function(action) {
    return {
      subscribable: "Scroll",
      action
    };
  }
};
