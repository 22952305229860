/* @flow */

import React from "react";
import "./styles.scss";
import t from "../../translation";

type Props = {
    beneficiaries: Element<any>[]
};

export default function BeneficialOwnerSummary(props: Props) {
    const {
        beneficiaries
    } = props;

    return (
        <div className="beneficial-owner">
            <h2 className="beneficial-owner__h2">{t`Uppgifter om verklig huvudman`}</h2>
            <div className="beneficial-owner__border--blue">
                <div className="beneficial-owner__item">
                    <p>
                        {t`Nedanstående uppgifter har hämtats från Bolagsverkets register över verkliga huvudmän. Om uppgifterna inte stämmer ber vi er registrera korrekta uppgifter på Bolagsverket.se.`}
                    </p>
                    <p>
                        {t`Genom att signera avtalet bekräftar du att uppgifterna är korrekta.`}
                    </p>
                </div>
            </div>
            {(beneficiaries || []).map((x, i) =>
                <div className="beneficial-owner__border">
                    <div className="beneficial-owner__item">
                        <div key={i} className="row">
                            <div className="col-xs-12 col-sm-6 company-ownership__divider">
                                {x.name}
                            </div>
                            <div className="col-xs-12 col-sm-6 company-ownership__divider">                                
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
