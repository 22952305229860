/* @flow */

import React from "react";
import ProductSummary from "../../components/ProductSummary";
import { actions, init, update } from "./state";


import "./styles.scss";

export type Props = {
  shippingCost: string,
  fundedTotal: string,
  products: any[]
};

export default function ProductSummaryView(props: Props) {
  const { shippingCost, fundedTotal, products } = props;
  return (
    <div className="row page-break-before">
      <div className="col-xs-12">
        <div className="product-summary__container">
          <ProductSummary
            productLabel="Produkt"
            quantityLabel="Antal"
            priceLabel="Pris"
            deliveryLabel="Leverans"
            freightLabel="Frakt"
            freight={shippingCost}
            currency="kr"
            vatLabel="Moms"
            vat={300}
            totalLabel={"Totalt exkl. moms"}
            totalPrice={fundedTotal}
          >
            {(products || [])
              .map((product, index) =>
                <ProductSummary.Item
                  key={index}
                  name={product.product_name}
                  image={product.image_url}
                  articleNumberLabel="Art nr. "
                  articleNumber={product.product_id}
                  quantity={product.quantity}
                  price={product.funded_amount_ex_vat}
                  currency="kr"
                />
              )}
          </ProductSummary>
        </div>
      </div>
    </div>
  );
}

ProductSummaryView.actions = actions;
ProductSummaryView.init = init;
ProductSummaryView.update = update;
