import React, { Component } from "react";
import { renderRoutes } from "../../lib/routes";
import { postMessage } from "../../messageHandler";
import ReactResizeDetector from "react-resize-detector";

import "./typography.scss";
import "./grid.scss";
import "./styles.css";

export default class Site extends Component {
  _onResize() {

    if (window.self !== window.top) {
      var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName("body")[0],
        height = body.clientHeight || documentElement.clientHeight;

      postMessage.resize(height);
    }
  }

  render() {    
    const { route } = this.props;    
    return (
      <div className="site">
        {renderRoutes(route.routes, this.props)}
        <ReactResizeDetector
          handleWidth={true}
          handleHeight={true}
          onResize={this._onResize.bind(this)}/>
      </div>
    );
  }
}
