import { handleActions } from "../../lib/actions";
import ProductSummary from "../ProductSummary";
import OriginalProductSummary from "../OriginalProductSummary";
import AgreementStatusSignatory from "../AgreementStatusSignatory";
import FinancingSummary from "../FinancingSummary";
import AgreementSummaryV2 from "../AgreementSummaryV2";
import AgreementStatusAddresses from "../AgreementStatusAddresses";
import SignAgreement from "../SignAgreement";
import AgreementSignStatus from "../AgreementSignStatus";
import DefaultTestModeIndicator from "../DefaultTestModeIndicator";

export const init = {
    loaded: false,
    agreementState: "",
    agreementSignStatus: AgreementSignStatus.init,
    agreementStatusSignatory: AgreementStatusSignatory.init,
    agreementAddresses: AgreementStatusAddresses.init,
    defaultTestModeIndicator: DefaultTestModeIndicator.init,
};

export const reducer = handleActions(
    {
        CONTRACT_LOADED: (state, action) => {
            return {
                ...state,
                loaded: true,
                id: action.payload,
                agreementState: action.payload.state
            };
        },
        otherwise: (state, action) => ({
            ...state,
            agreementSignStatus: AgreementSignStatus.update(
                state.agreementSignStatus,
                action
            ),
            agreementStatusSignatory: AgreementStatusSignatory.update(
                state.agreementStatusSignatory,
                action
            ),
            productSummary: ProductSummary.update(state.productSummary, action),
            originalProductSummary: OriginalProductSummary.update(state.originalProductSummary, action),
            financingSummary: FinancingSummary.update(state.financingSummary, action),
            agreementSummary: AgreementSummaryV2.update(state.agreementSummary, action),
            agreementAddresses: AgreementStatusAddresses.update(state.agreementAddresses, action),
            signAgreement: SignAgreement.update(state.signAgreement, action),
            defaultTestModeIndicator: DefaultTestModeIndicator.update(state.defaultTestModeIndicator, action)
        })
    },
    init
);
