import React from "react";
import actions from "./actions";
import { init, reducer } from "./reducer";

import "./styles.css";

export default function ErrorPage(props) {  
  const { status, statusText, xCorrelationId, value } = props.errorPage;

  const message = typeof value === "object" ? value.developer_message : value;

  return (
    <div className="error-container">
      <h1>
        <span className="status-code">{status}</span> {statusText}
      </h1>
      <h2>
        {message}
      </h2>
      <span className="error-container__correlation-id">
        {`Correlation id: `} {xCorrelationId}
      </span>
    </div>
  );
}

ErrorPage.actions = actions;
ErrorPage.init = init;
ErrorPage.reducer = reducer;
