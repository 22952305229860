/* @flow */

import { createActions, handleActions } from "../../lib/actions";

export const actions = {
  ...createActions({})
};

export type State = {
  firstPayment: Object,
  monthlyPayment: Object,
  lastPayment: Object,
  contractLength: string
};

export const init = {
  firstPayment: {},
  monthlyPayment: {},
  lastPayment: {},
  contractLength: ""
};

export const update = handleActions(
  {
    GET_AGREEMENT_SUCCESS: (state, action) => ({
      ...state,
      firstPayment: action.payload.value.first_payment,
      monthlyPayment: action.payload.value.monthly_payment,
      lastPayment: action.payload.value.last_payment,
      contractLength: action.payload.value.contract_length
    })
  },
  init
);
